.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

header {
  background-color: #000;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

header div {
  display: flex;
  align-items: center;
}

header h1 {
  color: white;
  font-size: calc(10px + 2vmin);
  margin: 0;
}

header p {
  color: #ccc;
  margin-left: 0.5em;
}

.header-staging,
.header-staging h1,
.header-staging p {
  background-color: rgb(255, 200, 50);
  color: #000;
}

.header-staging .nav-hamb__icon__line {
  background-color: #000;
}

.user-title {
  font-size: 18px;
  font-weight: bold;
}

.mode-title {
  color: white;
  margin-left: 20px;
  font-weight: bold;
}

.nav-menu__select-mode {
  background: #000;
  border: 1px solid #ccc;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  padding: 0.3em;
}

.mode-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

.mode-buttons button {
  background: #fff;
  border: 1px solid #000;
  cursor: pointer;
  color: #000;
  font-size: 1.2rem;
  padding: 0.75em;
  margin: 0.5em 0;
  width: 14em;
}

button.logout {
  color: #fff;
  background: #365690;
  border: 1px solid #365690;
}

.nav-hamb,
.nav-x {
  background: transparent;
  border: none;
  height: 45px;
  padding: 0 0 0 8px;
  cursor: pointer;
}

.nav-hamb__icon,
.nav-x__icon {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  width: 30px;
}
.nav-hamb__icon__line {
  background: #fff;
  height: 4px;
  width: 100%;
  margin: 3px 0;
}

.nav-x {
  position: relative;
  top: -15px;
  right: -12px;
  margin-left: auto;
}

.nav-x__icon__line {
  background: #000;
  height: 4px;
  width: 100%;
  margin: 3px 0;
}

.nav-x__icon__line:nth-child(1) {
  transform: rotate(45deg);
}

.nav-x__icon__line:nth-child(2) {
  position: relative;
  top: -10px;
  right: 0px;
  transform: rotate(-45deg);
}

.nav-menu {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: -100vw;
  padding: 1em;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 2;
  transition: right ease-in-out 0.25s;
}

.nav-menu__wrapper.show .nav-menu {
  right: 0;
}

main {
  padding-bottom: 84px;
  padding-top: 10px;
}

h4 {
  margin: 0.5em 0;
}

.reception-priority {
  background: rgb(54, 86, 144);
  color: white;
  margin: 0.45em 0;
  padding: 0.2em;
  position: relative;
}

.reception-priority span {
  background: white;
  cursor: pointer;
  color: rgb(54, 86, 144);
  position: absolute;
  padding: 0px 7px 3px 7px;
  line-height: 1;
  right: 4px;
  border-radius: 50%;
}

.orders-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orders-title ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.orders-title li {
  margin-left: 1.25em;
}

.orders-title li:first-child {
  margin-left: 0.5em;
  list-style: none;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sku_form {
  display: flex;
  align-items: center;
  justify-content: left;
}
.sku_form button {
  padding: 10px;
  margin: 5px;
}

input:not([type="checkbox"]) {
  width: 70%;
  padding: 10px 5px;
  margin: 5px;
}

select {
  padding: 10px 0px;
}

.sku-container {
  text-align: left;
  margin: 10px;
  border: 1px solid #ccc;
  font-size: 0.875rem;
  padding: 5px;
}

.sku-container__top {
  display: flex;
  align-items: center;
}

.sku-container__title,
.sku-container__order,
.sku-container__altidollars_refund {
  margin: 5px;
}

.sku-container__altidollars_refund {
  font-size: 0.75rem;
  font-weight: bold;
}

 .sku-container .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
} 

.processing__sku__checkboxes .checkbox-container {
  margin: 7px 0;
}
.sku-container .actions button {
  margin: 5px;
  padding: 10px;
}
.sku-container .actions .checkbox-container {
  flex-grow: 1;
}

input.validate-item-order__input:not([type="checkbox"]) {
  border: 1px solid rgb(175, 175, 175);
  border-right: none;
  margin-right: 0;
  padding: 11px 5px;
}

.validate-item-order__select {
  margin-right: 5px;
  padding: 10px 3px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  height: 25px;
  position: relative;
  padding-left: 35px;
  margin-left: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dialog-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
.dialog-content {
  width: 200px;
  opacity: 1;
  background-color: #fff;
  position: fixed;
  left: calc(50% - 100px);
  top: calc(50% - 190px);
  z-index: 999;
  pointer-events: auto;
  cursor: auto;
  visibility: visible;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);
}
.dialog-content__message {
  padding: 0.5em;
  max-height: 250px;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-word;
}
.dialog-content form {
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.dialog-content form > button.action {
  background: #365690;
  color: #fff;
}
.dialog-content form > button {
  flex: 50%;
  margin: 0 auto;
  color: #333;
  border: none;
  border-top: 1px solid #aaa;
  cursor: pointer;
  text-align: center;
  background: #fff;
  padding: 10px;
}
.dialog-content form > button:hover {
  background: #365690;
}
.dialog-content form > button:hover {
  color: #fff;
  background: #365690;
}

.dialog-enter .dialog-content {
  transition: transform 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: center center;
  transform: scale(0.9);
}
.dialog-enter-active .dialog-content {
  transform: scale(1);
}
.dialog-exit .dialog-content {
  transform: scale(1);
  transition: transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.dialog-exit-active .dialog-content {
  transform: scale(0.9);
}

.message-container {
  position: fixed;
  bottom: -40px;
  padding: 2px;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  width: 100%;
  min-height: 43px;
  transition: bottom 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-background.success {
  background: #28da5d;
}
.message-background.error {
  background: red;
}
.message-enter-done {
  bottom: 0;
}
.message-exit .message-content {
  bottom: -40px;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

footer button {
  background-color: #333;
  padding: 14px;
  border: 1px solid #ccc;
  color: #fff;
  width: 50%;
}

footer button.blue {
  background: #365690;
}

.restocking-fee__container {
  display: flex;
  padding: 5px;
  justify-content: center;
  width: 100%;
  background: white;
  border-top: 1px solid #d6d6d6;
}

.restocking-fee__container .checkbox-container {
  margin-bottom: 0;
  font-size: 14px;
}

.restocking-fee__container__text span {
  text-align: left;
  display: block;
}